import React, { useContext, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import {
  Card,
  CardHeader,
  Col,
  Container,
  InputGroup,
  Row,
  Table,
} from "reactstrap";
import Datetime from "react-datetime";

import PageHeader from "../../../components/Headers/PageHeader.jsx";
import DownloadButton from "../../../components/Buttons/DownloadButton.jsx";
import UploadButton from "../../../components/Buttons/UploadButton.jsx";
import {
  getBackrestContracts,
  getBackrestLeases,
  getBackupOtrosPagos,
  getBackrestRefunds,
  getBackrestServipag,
  getBackupTransfers,
  getExcelConsolidated,
  getExcelDirectPayments,
  getExcelGuaranteedPayments,
  getExcelOnlyBrokerage,
  getExcelNormalPayments,
  getInvoicingDetail,
  getReservationRefunds,
  getBankAccountsRegister,
  getOutflowsPayroll,
  getPayrollMX,
  getRegistrationFormMX,
  postBankAccountsRegister,
  postOutflowsPayroll,
  putPayrollMX,
  putRegistrationFormMX,
  getBackrestGuaranteedLeases,
  getBackrestNormalLeases,
  getBackrestGuaranteedContractPayments,
  getBackrestNormalContractPayment,
} from "../../../api";
import { AdminStateContext } from "../../../providers/AdminStateProvider.jsx";
import { useAuthToken } from "../../../hooks/AuthHook.jsx";

const Downloads = () => {
  const [adminState] = useContext(AdminStateContext);
  const [date, setDate] = useState(moment());
  const authToken = useAuthToken();
  const { country, legacyToken } = adminState;
  const downloads = {
    cl: [
      {
        downloadHandler: getExcelNormalPayments,
        name: "Nómina de pagos: Plan básico",
        filename: "pagos.basico",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getExcelGuaranteedPayments,
        name: "Nómina de pagos: Plan garantizado",
        filename: "pagos.premium",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getExcelDirectPayments,
        name: "Nómina de pagos: Transf. directas a la cuenta",
        filename: "pagos.directos",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getExcelOnlyBrokerage,
        name: "Nómina de pagos: Contratos solo corretaje",
        filename: "pagos.brokerage",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getReservationRefunds,
        name: "Nómina de pagos: Devoluciones",
        filename: "reembolsos",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getBackrestLeases,
        name: "Respaldo contabilidad: Mensualidades",
        filename: "respaldos.mensualidades",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getBackrestGuaranteedLeases,
        name: "Respaldo contabilidad: Mensualidades plan garantizado",
        filename: "respaldos.mensualidades.garantizado",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getBackrestNormalLeases,
        name: "Respaldo contabilidad: Mensualidades plan básico",
        filename: "respaldos.mensualidades.basico",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getBackrestContracts,
        name: "Respaldo contabilidad: Cierres",
        filename: "respaldos.contratos",
        fileExtension: "xlsx",
        isV3: false,
      },

      {
        downloadHandler: getBackrestGuaranteedContractPayments,
        name: "Respaldo contabilidad: Cierres plan garantizado",
        filename: "respaldos.contratos.garantizado",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getBackrestNormalContractPayment,
        name: "Respaldo contabilidad: Cierres plan básico",
        filename: "respaldos.contratos.basico",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getBackupOtrosPagos,
        name: "Respaldo contabilidad: Ingresos Otros pagos",
        filename: "respaldos.otros_pagos",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getBackrestServipag,
        name: "Respaldo contabilidad: Ingresos Servipag",
        filename: "respaldos.servipag",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getBackupTransfers,
        name: "Respaldo contabilidad: Ingresos por transferencias",
        filename: "respaldos.transferencias",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getBackrestRefunds,
        name: "Respaldo contabilidad: Reembolsos",
        filename: "respaldos.reembolsos",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getExcelConsolidated,
        name: "Consolidado: Mensualidades y Pagos Suscripción",
        filename: "consolidado",
        fileExtension: "xlsx",
        isV3: false,
      },
    ],
    co: [
      {
        downloadHandler: getBankAccountsRegister,
        uploadHandler: postBankAccountsRegister,
        formDataKey: "accounts",
        name: "Registro TXT: Cuentas",
        filename: "cuentas_propietarios",
        fileExtension: "txt",
      },
      {
        downloadHandler: getOutflowsPayroll,
        uploadHandler: postOutflowsPayroll,
        formDataKey: "payments",
        name: "Registro TXT: Pagos",
        filename: "pagos_propietarios",
        fileExtension: "txt",
      },
      {
        downloadHandler: getInvoicingDetail,
        name: "Detalle facturación",
        filename: "invoicing_co",
        fileExtension: "xlsx",
      },
    ],
    mx: [
      {
        downloadHandler: getRegistrationFormMX,
        uploadHandler: putRegistrationFormMX,
        formDataKey: "accounts",
        name: "Registro TXT: Alta de cuentas - combinado",
        filename: "cuenta_propietarios",
        fileExtension: "txt",
      },
      {
        downloadHandler: getPayrollMX(true),
        uploadHandler: putPayrollMX(true),
        formDataKey: "payments",
        name: "Registro TXT: Alta de pagos a cuentas Santander",
        filename: "pagos_propietarios_santander",
        fileExtension: "txt",
      },
      {
        downloadHandler: getPayrollMX(false),
        uploadHandler: putPayrollMX(false),
        formDataKey: "payments",
        name: "Registro TXT: Alta de pagos a cuentas interbancarias",
        filename: "pagos_propietarios_interbancario",
        fileExtension: "txt",
      },
    ],
  };

  const downloadFile = async (
    filename,
    fetchFunc,
    fileExtension,
    isV3 = false
  ) => {
    let token = legacyToken;
    if (isV3) {
      token = authToken;
    }
    const response = await fetchFunc(token, {
      _from: date.format("YYYY-MM-DD"),
      _to: date.format("YYYY-MM-DD"),
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([response]));
    const name = `${filename}.${date.format("DD-MM-YY")}.${fileExtension}`;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const uploadFile = async (fileRef, formDataKey, uploadFunc) => {
    if (fileRef?.current?.files?.length) {
      const body = new FormData();
      body.append(formDataKey, fileRef.current.files[0]);
      const { response } = await uploadFunc(authToken, body);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Carga exitosa",
          text: response,
        });
      }
    }
  };

  return (
    <>
      <PageHeader title={"Descargas"} />
      <Container>
        <Row className="justify-content-center">
          <Col xl={8}>
            <Card>
              <CardHeader className="border-0 m-auto">
                <Row className="align-items-center m-auto">
                  <span className="mr-4">Fecha</span>
                  <InputGroup className="input-group-alternative col">
                    <Datetime
                      value={date}
                      timeFormat={false}
                      onChange={setDate}
                      closeOnSelect
                      dateFormat="DD/MM/YYYY"
                    />
                  </InputGroup>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Item</th>
                    <th>Descargar</th>
                    <th>Cargar documento</th>
                  </tr>
                </thead>
                <tbody>
                  {downloads[country.toLowerCase()].map((dwnld) => (
                    <tr key={dwnld.filename}>
                      <td>{dwnld.name}</td>
                      <td className="table-actions text-center">
                        {dwnld.downloadHandler && (
                          <DownloadButton
                            onClick={(e) =>
                              downloadFile(
                                dwnld.filename,
                                dwnld.downloadHandler,
                                dwnld.fileExtension,
                                country.toLowerCase() !== "cl" || dwnld.isV3 // TODO: Enable auth0 token for all countries
                              )
                            }
                            text="Descargar"
                          />
                        )}
                      </td>
                      <td className="table-actions text-center">
                        {dwnld.uploadHandler && (
                          <UploadButton
                            onClick={(ref) =>
                              uploadFile(
                                ref,
                                dwnld.formDataKey,
                                dwnld.uploadHandler
                              )
                            }
                            text="Subir documento"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Downloads;
