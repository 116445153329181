import { env } from "../env.js";

const PAYMENTS_API_V1_COMMON = env.REACT_APP_PAYMENTS_COMMON_V1_URL;
const PAYMENTS_API_V2_CL = env.REACT_APP_PAYMENTS_CL_V2_URL;
const PAYMENTS_API_V3_CL = env.REACT_APP_PAYMENTS_CL_V3_URL;
const PAYMENTS_API_V3_CO = env.REACT_APP_PAYMENTS_CO_V3_URL;
const PAYMENTS_API_V3_MX = env.REACT_APP_PAYMENTS_MX_V3_URL;
const BACKEND_URL = env.REACT_APP_BACKEND_URL;
const COMMISSIONS_API_BASE_URL = env.REACT_APP_MS_COMMISSIONS_V1_URL;

export const accounting_urls = {
  cl: {
    backup: {
      otrosPagos: (_from, _to) =>
        `${PAYMENTS_API_V3_CL}/reports/backup?concept=otros_pagos&from=${_from}&to=${_to}`,
      transfers: (_from, _to) =>
        `${PAYMENTS_API_V3_CL}/reports/backup?concept=transfers&from=${_from}&to=${_to}`,
    },
    movements: {
      get: {
        all: (type, concept, status, _from, _to) =>
          `${PAYMENTS_API_V3_CL}/movements?type=${type}&concept=${concept}&status=${status}&from=${_from}&to=${_to}`,
        single: (_id) => `${PAYMENTS_API_V3_CL}/movements/${_id}`,
        group: (_id) => `${PAYMENTS_API_V3_CL}/movements/groups/${_id}`,
      },
    },
    people: {
      bank_accounts: {
        all: (_id) => `${PAYMENTS_API_V3_CL}/people/${_id}/bank_accounts`,
        record: (personId, identifier) =>
          `${PAYMENTS_API_V3_CL}/people/${personId}/bank_accounts/${identifier}/record`,
      },
    },
    subscriptions: {
      movements: {
        get: (_id, getOnly, _from, _to) =>
          `${PAYMENTS_API_V3_CL}/subscriptions/${_id}/movements?type=${getOnly}&from=${_from}&to=${_to}`,
      },
    },
    servipag: {
      uploadTxt: () => `${PAYMENTS_API_V3_CL}/thirdparties/servipag/logs`,
      logs: {
        get: {
          all: (_from, _to) =>
            `${PAYMENTS_API_V3_CL}/thirdparties/servipag/logs?from=${_from}&to=${_to}`,
          movement: (servipagUid) =>
            `${PAYMENTS_API_V3_CL}/thirdparties/servipag/logs/${servipagUid}/movement`,
        },
      },
    },
    commissions: {
      appraisers: {
        commission: (id, from, to) =>
          `${COMMISSIONS_API_BASE_URL}/new/cl/commissions/appraiser/${id}?from_date=${from}&to_date=${to}`,
        report: (id, from, to, name) =>
          `${COMMISSIONS_API_BASE_URL}/new/cl/reports/appraiser/${id}?from_date=${from}&to_date=${to}&name=${name}`,
        allReports: (from, to) =>
          `${COMMISSIONS_API_BASE_URL}/new/cl/reports/appraisers?from_date=${from}&to_date=${to}`,
        resume: (from, to) =>
          `${COMMISSIONS_API_BASE_URL}/new/cl/reports/appraisers/resume?from_date=${from}&to_date=${to}`,
        bankReport: (from, to) =>
          `${COMMISSIONS_API_BASE_URL}/new/cl/reports/appraisers/bank?from_date=${from}&to_date=${to}`,
      },
      executives: {
        commission: (id, from, to) =>
          `${COMMISSIONS_API_BASE_URL}/cl/v1/commissions/executive/${id}?from_date=${from}&to_date=${to}`,
        report: (id, to, name) =>
          `${COMMISSIONS_API_BASE_URL}/cl/v1/reports/executive/${id}?to_date=${to}&name=${name}`,
      },
    },
    payroll: {
      houmers: () => `${PAYMENTS_API_V3_CL}/tickets/payroll`,
      checkDuplicated: (from, to, type, get_duplicates) =>
        `${PAYMENTS_API_V3_CL}/check/payroll?from=${from}&to=${to}&type=${type}&get_duplicates=${get_duplicates}`,
      uploadPayroll: (date, type) =>
        `${PAYMENTS_API_V3_CL}/check/payroll?date=${date}&type=${type}`,
    },
  },
  co: {
    downloads: {
      bankAccounts: {
        register: (from_, to_) =>
          `${PAYMENTS_API_V3_CO}/downloads/bank_accounts/registration_form?from=2021-01-01&to=${to_}`,
      },
      movements: {
        invoicing: () => `${PAYMENTS_API_V3_CO}/downloads/movements/invoicing`,
        invoicing_report: (dateFrom, dateTo) =>
          `${PAYMENTS_API_V3_CO}/downloads/movements/invoicing_report?from=${dateFrom}&to=${dateTo}`,
        payroll: (from_, to_) =>
          `${PAYMENTS_API_V3_CO}/downloads/movements/payroll?type=outflow&concept=all&plan_type=all&method=all&from=${from_}&to=${to_}`,
      },
    },
    movements: {
      get: {
        all: (type, concept, status, _from, _to) =>
          `${PAYMENTS_API_V3_CO}/movements?type=${type}&concept=${concept}&status=${status}&from=${_from}&to=${_to}`,
        single: (_id) => `${PAYMENTS_API_V3_CO}/movements/${_id}`,
        group: (_id) => `${PAYMENTS_API_V3_CO}/movements/groups/${_id}`,
      },
    },
    people: {
      bank_accounts: {
        all: (_id) => `${PAYMENTS_API_V3_CO}/people/${_id}/bank_accounts`,
        record: (personId, identifier) =>
          `${PAYMENTS_API_V3_CO}/people/${personId}/bank_accounts/${identifier}/record`,
        register: (personId, identifier) =>
          `${PAYMENTS_API_V3_CO}/people/${personId}/bank_accounts/${identifier}/register`,
      },
    },
    subscriptions: {
      movements: {
        get: (_id, getOnly, _from, _to) =>
          `${PAYMENTS_API_V3_CO}/subscriptions/${_id}/movements?type=${getOnly}&from=${_from}&to=${_to}`,
      },
    },
    uploads: {
      bankAccounts: {
        register: () => `${PAYMENTS_API_V3_CO}/people/register`,
      },
      movements: {
        payroll: () => `${PAYMENTS_API_V3_CO}/movements/register_payment`,
      },
    },
  },
  mx: {
    downloads: {
      registrationForm: (to) =>
        `${PAYMENTS_API_V3_MX}/downloads/bank_accounts/registration_form?from=2021-01-01&to=${to}`,
      payroll: (from, to, internal) =>
        `${PAYMENTS_API_V3_MX}/downloads/movements/payroll?type=outflow&concept=all&plan_type=all&method=all&from=${from}&to=${to}&internal=${internal}`,
    },
    movements: {
      get: {
        all: (type, concept, status, from, to) =>
          `${PAYMENTS_API_V3_MX}/movements?type=${type}&concept=${concept}&status=${status}&from=${from}&to=${to}`,
        single: (movementId) => `${PAYMENTS_API_V3_MX}/movements/${movementId}`,
        group: (groupId) => `${PAYMENTS_API_V3_MX}/movements/groups/${groupId}`,
      },
    },
    subscriptions: {
      movements: {
        get: (subscriptionId, movementType, from, to) =>
          `${PAYMENTS_API_V3_MX}/subscriptions/${subscriptionId}/movements?type=${movementType}&from=${from}&to=${to}`,
      },
    },
    people: {
      bankAccounts: {
        all: (personId) =>
          `${PAYMENTS_API_V3_MX}/people/${personId}/bank_accounts`,
        single: (personId, identifier) =>
          `${PAYMENTS_API_V3_MX}/people/${personId}/bank_accounts/${identifier}`,
      },
    },
    uploads: {
      registrationForm: () => `${PAYMENTS_API_V3_MX}/people/register`,
      payroll: (internal) =>
        `${PAYMENTS_API_V3_MX}/movements/register_payment?internal=${internal}`,
    },
  },
  common: {
    adjustments: {
      single: (_id) =>
        `${PAYMENTS_API_V1_COMMON}/reports/leases/${_id}/adjustments`,
    },
    backrest: {
      consolidated: () => `${PAYMENTS_API_V1_COMMON}/reports/consolidated`,
      contracts: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/backrest/contracts?from=${_from}&to=${_to}`,
      leases: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/backrest/leases?from=${_from}&to=${_to}`,
      refunds: (_from, _to) =>
        `${PAYMENTS_API_V2_CL}/movements/income/reservations/backup?from=${_from}&to=${_to}`,
      servipag: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/backrest/servipag?from=${_from}&to=${_to}`,
      GuaranteedLeases: (_from, _to) =>
        `${PAYMENTS_API_V3_CL}/cashflow/breakdown/report?from=${_from}&to=${_to}&plan=GUARANTEED`,
      NormalLeases: (_from, _to) =>
        `${PAYMENTS_API_V3_CL}/cashflow/breakdown/report?from=${_from}&to=${_to}&plan=NORMAL`,
      contractPaymentGuaranteed: (_from, _to) =>
        `${PAYMENTS_API_V3_CL}/cashflow/contracts/report?from=${_from}&to=${_to}&plan=GUARANTEED`,
      contractPaymentNormal: (_from, _to) =>
        `${PAYMENTS_API_V3_CL}/cashflow/contracts/report?from=${_from}&to=${_to}&plan=NORMAL`,
    },
    payroll: {
      directPayments: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/outflows/direct_payments?from=${_from}&to=${_to}`,
      guaranteedPayments: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/outflows/guaranteed_payments?from=${_from}&to=${_to}`,
      normalPayments: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/outflows/servipag?from=${_from}&to=${_to}`,
      onlyBrokerage: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/outflows/only_brokerage_contracts?from=${_from}&to=${_to}`,
      refunds: (filename, _from, _to) =>
        `${PAYMENTS_API_V2_CL}/movements/payroll?type=outflow&concept=reservation&plan_type=all&method=all&filename=${filename}&from=${_from}&to=${_to}`,
    },
    reports: {
      contracts: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/contracts?from=${_from}&to=${_to}`,
      leases: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/monthly_leases?from=${_from}&to=${_to}`,
      reservations: (_from, _to) =>
        `${PAYMENTS_API_V1_COMMON}/reports/reservations?from=${_from}&to=${_to}`,
    },
    movements: {
      search: (field, value) =>
        `${PAYMENTS_API_V1_COMMON}/transactions/search?search_by=${field}&${field}=${value}`,
    },
  },
  appraisers: {
    all: () => `${BACKEND_URL}/schedules/get_all_appraisers`,
  },
  executives: {
    all: () => `${BACKEND_URL}/sellers`,
  },
  users: {
    // se ocupa, solo tiene v1
    user: (id) => `${PAYMENTS_API_V1_COMMON}/users/${id}`,
  },
};
